import { AxiosError } from 'axios'
import { z } from 'zod'
import { objectToSnake } from 'ts-case-convert'
import { API, handleError, handleZodError } from './api'
import { DISTRIBUTION_CENTERS, IN_STOCK, TEMP_OUT, UNAVAILABLE } from '../constants'
import { capitalCase, cleanObject } from '../helpers'
import {
  collectionBrandSchema,
  collectionExtendedSchema,
  collectionFiltersSchema,
  collectionSchema,
  fabricAutocompleteSchema,
  fabricExtendedSchema,
  fabricFullSchema,
  fabricImageSchema,
  priceTierSchema,
  visualizerFabricSchema,
} from './schemas'

//* ---------------- APIS ---------------- *//
export async function getFabric(id: number) {
  try {
    const response = await API.get(`fabrics/${id}`)
    const fabric = fabricFullSchema.parse(response.data)

    return fabric
  } catch (error) {
    return handleError(error, 'Fabric')
  }
}

export async function getFabrics(rawParams: Record<string, unknown>) {
  try {
    const cleanedParams = cleanObject(rawParams)
    const params = getFabricsParamsSchema.parse(cleanedParams)

    const response = await API.get('fabrics?reverse_sort=true', { params })
    const fabrics = z.array(fabricExtendedSchema).parse(response.data)

    return { fabrics, totalFabrics: response.headers.total }
  } catch (error) {
    return handleError(error, 'Fabrics')
  }
}

export async function getAutocompleteFabrics(rawParams: Record<string, unknown>) {
  try {
    const params = getFabricsParamsSchema.parse(rawParams)

    const response = await API.get('fabrics?reverse_sort=true&autocomplete=true', { params })
    const fabrics = z.array(fabricAutocompleteSchema).parse(response.data)

    return fabrics
  } catch (error) {
    return handleError(error, 'Fabrics')
  }
}

export async function getRelatedFabrics(id: number) {
  try {
    const response = await API.get(`fabrics/${id}/related?rpp=8`)
    const fabrics = z.array(fabricExtendedSchema).parse(response.data)

    return fabrics
  } catch (error) {
    return handleError(error, 'Related Fabrics')
  }
}

export async function createFabricFavorite(fabric_id: number) {
  try {
    const response = await API.post('fabric_favorites', { fabric_id })

    return response.data
  } catch (error) {
    return handleError(error, 'Create Fabric Favorite')
  }
}

export async function deleteFabricFavorite(id: number) {
  try {
    const response = await API.delete(`fabric_favorites/${id}`)

    return response.data
  } catch (error) {
    return handleError(error, 'Delete Fabric Favorite')
  }
}

export async function createFabricNotification(rawParams: Record<string, unknown>) {
  try {
    const cleanParams = createFabricNotificationSchema.safeParse(rawParams)

    if (cleanParams.success) {
      const response = await API.post('fabric_notifications', cleanParams.data)
      return { error: false, data: response.status }
    } else {
      const errors = handleZodError(cleanParams.error)
      return { error: true, data: errors }
    }
  } catch (error) {
    return handleError(error, 'Create Fabric Notification')
  }
}

export async function getCollection(id: number | 'favorites') {
  try {
    const response = await API.get(`collections/${id}`)
    const collection = collectionExtendedSchema.parse(response.data)

    return collection
  } catch (error) {
    return handleError(error, 'Collection')
  }
}

export async function getCollections(rawParams: Record<string, unknown>) {
  try {
    const parsedParams = getCollectionsParams.parse(rawParams)
    const params = cleanObject(parsedParams)
    const response = await API.get('collections', { params })
    const collections = z.array(collectionSchema).parse(response.data)

    return { collections, totalCollections: response.headers.total }
  } catch (error) {
    return handleError(error, 'Collections')
  }
}

export async function getCollectionBrands() {
  try {
    const response = await API.get('collection_brands')
    const brands = z.array(collectionBrandSchema).parse(response.data)

    return brands
  } catch (error) {
    return handleError(error, 'Collection Brands')
  }
}

export async function getCollectionImages(rawParams: Record<string, unknown>) {
  try {
    const params = getCollectionImagesParams.parse(rawParams)

    const response = await API.get('visualizer_fabrics', { params })
    const fabricImages = z.array(fabricImageSchema).parse(response.data)

    return fabricImages
  } catch (error) {
    return handleError(error, 'Collection Images')
  }
}

export async function getCollectionFilters(rawParams: Record<string, unknown>) {
  try {
    const params = getCollectionFiltersParams.parse(rawParams)

    const response = await API.get('collections/filters', { params })
    const filters = collectionFiltersSchema.parse(response.data)

    return filters
  } catch (error) {
    return handleError(error, 'Collection Filters')
  }
}

export async function updateFabricAvailability(rawParams: Record<string, unknown>) {
  try {
    const params = updateFabricAvailabilitySchema.parse(rawParams)
    await API.post('fabrics/availability', params)

    return {
      error: false,
      message: `Fabric ${rawParams.supplierFabricNumber} status has been updated to ${capitalCase(
        String(rawParams.status),
      )}`,
    }
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 409) {
      return { error: true, message: String(error.response.data.errors) }
    } else {
      return handleError(error, 'Update Fabric Availability')
    }
  }
}

export async function getVisualizerFabric(rawParams: Record<string, unknown>) {
  try {
    const params = getVisualizerFabricParams.parse(rawParams)

    const response = await API.get('visualizer_fabrics/fabric', { params })
    const fabric = visualizerFabricSchema.parse(response.data)

    return fabric
  } catch (error) {
    return handleError(error, 'Visualizer Fabrics')
  }
}

//* ---------------- PARAMS ---------------- *//
const getFabricsParamsSchema = z
  .object({
    priceTier: z.array(priceTierSchema).optional(),
    discounted: z.string().optional(),
    namedFilter: z.array(z.string()).optional(),
    fabricType: z.array(z.string()).optional(),
    factory: z.array(z.string()).optional(),
    patternId: z.array(z.string()).optional(),
    primaryColorId: z.array(z.string()).optional(),
    weaveId: z.array(z.string()).optional(),
    hasImage: z.string().optional(),
    q: z.string().optional(),
    page: z.coerce.number().default(1),
    perPage: z.coerce.number().default(24),
    fabricNumber: z.string().optional(),
    stockAvailability: z.union([z.literal(IN_STOCK), z.literal(TEMP_OUT), z.literal(UNAVAILABLE)]).optional(),
  })
  .transform(({ stockAvailability, ...data }) => ({
    ...data,
    stockAvailability:
      stockAvailability === IN_STOCK ? 'in_stock' : stockAvailability === TEMP_OUT ? 'temp_out' : stockAvailability,
  }))
  .transform(objectToSnake)

const createFabricNotificationSchema = z
  .object({
    fabricId: z.coerce.number(),
    email: z.string().email(),
    factory: z.union([z.literal('iD'), z.literal('t2iD'), z.literal('Both')]),
    dealerNote: z.string().optional(),
  })
  .transform(({ email, factory, ...data }) => ({
    notificationEmail: email,
    distributionCenterId:
      factory === 'Both'
        ? DISTRIBUTION_CENTERS.map(dc => dc.id)
        : DISTRIBUTION_CENTERS.filter(dc => dc.abbreviation.toLowerCase() === factory.toLowerCase())[0]?.id,
    ...data,
  }))
  .transform(objectToSnake)

const getCollectionsParams = z
  .object({
    q: z.string().optional(),
    page: z.coerce.number().default(1),
    perPage: z.coerce.number().default(23),
    alphaSort: z.string().optional(),
    featured: z.string().optional(),
    reverseSort: z.string().default('true'),
    garmentType: z.string().optional(),
    trimGarmentType: z.string().optional(),
    isLive: z.string().default('true'),
    isDemo: z.string().optional(),
    crossCoded: z.enum(['true', 'false']).optional(),
    usage: z.array(z.enum(['shirting', 'clothing', 'shirting-trim', 'clothing-trim'])).optional(),
    year: z.array(z.string()).optional(),
    brand: z.array(z.string()).optional(),
    visualize: z.boolean().optional(),
  })
  .transform(({ alphaSort, featured, reverseSort, isLive, isDemo, ...data }) => ({
    ...data,
    alphaSort: alphaSort === 'true' ? 1 : undefined,
    featured: featured === 'true' ? 1 : undefined,
    reverseSort: reverseSort === 'true' ? 1 : undefined,
    isLive: isLive === 'true' ? 1 : undefined,
    isDemo: isDemo === 'true' ? 1 : undefined,
  }))
  .transform(objectToSnake)

const getCollectionImagesParams = z
  .object({
    collectionId: z.coerce.number(),
    garmentType: z.coerce.number().optional(),
  })
  .transform(objectToSnake)

const getCollectionFiltersParams = z
  .object({
    isLive: z.string().default('true'),
    crossCoded: z.enum(['true', 'false']).optional(),
    usage: z.array(z.enum(['shirting', 'clothing', 'shirting-trim', 'clothing-trim'])).optional(),
    trimGarmentType: z.string().optional(),
    year: z.array(z.string()).optional(),
    visualize: z.boolean().optional(),
  })
  .transform(objectToSnake)

const updateFabricAvailabilitySchema = z
  .object({
    supplierFabricNumber: z.string(),
    inStock: z.union([z.literal(0), z.literal(1)]),
    supplierEnabled: z.union([z.literal(0), z.literal(1)]),
    restockDate: z.coerce.date().optional(),
  })
  .transform(objectToSnake)

const getVisualizerFabricParams = z
  .object({
    baseModelId: z.coerce.number(),
    fabricId: z.coerce.number().optional(),
    liningId: z.coerce.number().optional(),
    buttonId: z.coerce.number().optional(),
    threadId: z.coerce.number().optional(),
    zipperId: z.coerce.number().optional(),
    ribknitId: z.coerce.number().optional(),
  })
  .transform(objectToSnake)

//* ---------------- HELPERS ---------------- *//
