import { z } from 'zod'
import { objectToSnake } from 'ts-case-convert'
import { API, handleError } from './api'
import { cleanObject } from '../helpers'
import {
  buttonSchema,
  feltSchema,
  nameLabelSchema,
  pipingSchema,
  ribknitSchema,
  suedeSchema,
  threadSchema,
  trouserTrimSchema,
  zippersSchema,
} from './schemas'

//* ---------------- APIS ---------------- *//
export async function getButtons(rawParams: Record<string, unknown> = {}) {
  try {
    const params = cleanObject(getMaterialsParamsSchema.parse(rawParams))
    const response = await API.get('buttons', { params })

    const buttons = z.array(buttonSchema).parse(response.data)
    const totalButtons = Number(response.headers.total)

    return { buttons, totalButtons }
  } catch (error) {
    return handleError(error, 'Buttons')
  }
}

export async function getFelts(rawParams: Record<string, unknown> = {}) {
  try {
    const params = getMaterialsParamsSchema.parse(rawParams)
    const response = await API.get('felts', { params })

    const felts = z.array(feltSchema).parse(response.data)

    return felts
  } catch (error) {
    return handleError(error, 'Felts')
  }
}

export async function getSuedes(rawParams: Record<string, unknown> = {}) {
  try {
    const params = getMaterialsParamsSchema.parse(rawParams)
    const response = await API.get('suedes', { params })

    const suedes = z.array(suedeSchema).parse(response.data)

    return suedes
  } catch (error) {
    return handleError(error, 'Suedes')
  }
}

export async function getPipings(rawParams: Record<string, unknown> = {}) {
  try {
    const params = getMaterialsParamsSchema.parse(rawParams)
    const response = await API.get('material_pipings', { params })

    const pipings = z.array(pipingSchema).parse(response.data)

    return pipings
  } catch (error) {
    return handleError(error, 'Pipings')
  }
}

export async function getNameLabels(rawParams: Record<string, unknown> = {}) {
  try {
    const params = getMaterialsParamsSchema.parse(rawParams)
    const response = await API.get('labels', { params })

    const nameLabels = z.array(nameLabelSchema).parse(response.data)

    return nameLabels
  } catch (error) {
    return handleError(error, 'Name Labels')
  }
}

export async function getRibknits(rawParams: Record<string, unknown> = {}) {
  try {
    const params = getMaterialsParamsSchema.parse(rawParams)
    const response = await API.get('ribknits', { params })

    const ribknits = z.array(ribknitSchema).parse(response.data)

    return ribknits
  } catch (error) {
    return handleError(error, 'Ribknits')
  }
}

export async function getThreads(rawParams: Record<string, unknown> = {}) {
  try {
    const params = getMaterialsParamsSchema.parse(rawParams)
    const response = await API.get('material_threads', { params })

    //* Remove any materials without images - TODO: find a better way to do this
    const filteredData = filterMaterials(response.data)

    const threads = z.array(threadSchema).parse(filteredData)
    const totalThreads = Number(response.headers.total)

    return { threads, totalThreads }
  } catch (error) {
    return handleError(error, 'Threads')
  }
}

export async function getTrouserTrims(rawParams: Record<string, unknown> = {}) {
  try {
    const params = getMaterialsParamsSchema.parse(rawParams)
    const response = await API.get('material_trouser_trims', { params })

    const trouserTrims = z.array(trouserTrimSchema).parse(response.data)

    return trouserTrims
  } catch (error) {
    return handleError(error, 'Trouser Trims')
  }
}

export async function getZippers(rawParams: Record<string, unknown> = {}) {
  try {
    const params = getMaterialsParamsSchema.parse(rawParams)
    const response = await API.get('zippers', { params })

    const zippers = z.array(zippersSchema).parse(response.data)

    return zippers
  } catch (error) {
    return handleError(error, 'Zippers')
  }
}

//* ---------------- PARAMS ---------------- *//
const getMaterialsParamsSchema = z
  .object({
    garmentType: z.coerce.number().optional(),
    q: z.string().optional(),
    priceTier: z
      .string()
      .optional()
      .transform(v => (v === 'all' ? undefined : v)),
    page: z.coerce.number().optional().default(1),
    perPage: z.coerce.number().optional().default(1000),
  })
  .transform(objectToSnake)

//* ---------------- HELPERS ---------------- *//
const filterMaterials = <MaterialType extends { image: string | null }>(materials: MaterialType[]) =>
  materials.filter(({ image }) => image)
