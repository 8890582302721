import { COAT, COAT_PANT, PANT, SHIRT, VEST } from './garments'

//* ---------------- PRICES ---------------- *//
export const PRICE_PROFILES = [
  { id: 1, title: 'USD', symbol: '$', rate: 1 },
  { id: 13, title: 'GBP', symbol: '£', rate: 0.625 },
  { id: 15, title: 'EURO', symbol: '€', rate: 0.7 },
  { id: 16, title: 'RMB', symbol: '¥', rate: 6.39 },
  { id: 17, title: 'AUD', symbol: 'AUD', rate: 1.17 },
  { id: 19, title: 'CAN', symbol: 'CAN$', rate: 1.15 },
] as const

//* ---------------- DISTRIBUTION CENTERS ---------------- *//
export const T2ID = 'T2iD'
export const ID = 'iD'
export const FACTORYID = { id: 5, name: 'iDesign', location: 'China', abbreviation: ID } as const
export const FACTORYT2ID = { id: 6, name: 'Trisco', location: 'Indonesia', abbreviation: T2ID } as const

export const DISTRIBUTION_CENTERS = [FACTORYID, FACTORYT2ID] as const

//* ---------------- TAILORING GRADES ---------------- *//
export const TAILORING_GRADES = [
  { name: 't2id-dress', description: 'T2iD Dress', garmentTypes: [COAT_PANT, COAT, VEST, PANT, SHIRT] },
  { name: 't2id-casual', description: 'T2iD Casual', garmentTypes: [COAT, VEST, PANT] },
  { name: 'id-dress', description: 'iD Dress', garmentTypes: [COAT_PANT, COAT, VEST, PANT, SHIRT] },
  { name: 'id-mayfair', description: 'iD Mayfair', garmentTypes: [COAT_PANT, COAT, VEST, PANT] },
] as const

export const TailoringGradeArray = ['id-dress', 'id-mayfair', 't2id-casual', 't2id-dress'] as const

//* ---------------- SHIPPING ---------------- *//
export const validCouriers = ['FedEx', 'UPS', 'SFExpress', 'DHL', 'TNT', 'USPS', 'ChungShingExpress', 'Other'] as const
