export const WORKFLOW_URL: string = import.meta.env.VITE_PHP_URL
export const API_URL: string = import.meta.env.VITE_API_URL
export const DOCUMENTS_URL: string = import.meta.env.VITE_DOCUMENTS_URL
export const AWS_URL = 'https://trinity-apparel.s3.us-east-2.amazonaws.com'
export const PICARIO_URL = 'https://trinityapparel.picarioxpo.com/'

// AuthProvider
export const loginUrl = `${WORKFLOW_URL}login.php`

// Clients
export const downloadClientCSV = (token: string) => `${API_URL}customers/dashboard.csv?token=${token}`
export const downloadClientInsightCSV = (token: string, params: string) => `${downloadClientCSV(token)}&${params}`

// Client
export const clientMeasurementsUrl = (customerId: number) =>
  `${WORKFLOW_URL}dealer/customer_manager.php?cust_action=customer_measurements&customer_id=${customerId}`
export const clientImagesUrl = (customerId: number) =>
  `${WORKFLOW_URL}dealer/customer_manager.php?cust_action=customer_images&customer_id=${customerId}`

// Fabric Explorerer
export const downloadFabricStatus = (collectionId: number | 'favorites', dealerId?: number) =>
  `${DOCUMENTS_URL}/collections/${collectionId}/fabric_status?dealer_id=${dealerId}`
export const downloadFabricImage = (fabricId: number, token: string, fabricNumber: string, url: string) =>
  `${API_URL}fabrics/${fabricId}/image?token=${token}&filename=${fabricNumber}&url=${encodeURIComponent(url)}`

// Order Status
export const continueOrderUrl = (orderId: number) =>
  `${WORKFLOW_URL}dealer/order_manager.php?order_action=order_items&dealer_order_id=${orderId}`
export const viewOrderUrl = (orderId: number) => `${WORKFLOW_URL}share/order_status.php?dealer_order_id=${orderId}`
export const editOrderUrl = (orderId: number, garmentId: number) =>
  `${continueOrderUrl(orderId)}&trinity_review=1&dealer_edit=1&suit_id=${garmentId}`

// New Order
export const startOrderUrl = (customerId: number, orderType: string) =>
  `${WORKFLOW_URL}dealer/order_manager.php?order_action=order_create&order_type=${orderType}&customer_id=${customerId}`

// NavLinks - Workflow
export const orderPartsUrl = 'https://form.jotform.com/90624009784157'
export const searchOrdersUrl = `${WORKFLOW_URL}dealer/order_status.php?order_action=order_status`
export const incompleteOrdersUrl = `${WORKFLOW_URL}dealer/incomplete_orders.php`
export const universityUrl = `${WORKFLOW_URL}dealer/documents.php`
export const helpCenterUrl = 'http://help.trinity-apparel.com/'
export const measurementsGuideUrl = `${WORKFLOW_URL}learn/msmt_guide/Measurements.html`
export const returnProgramUrl = `${WORKFLOW_URL}dealer/40_40_Program.php?action=search`
export const policiesUrl = `${WORKFLOW_URL}dealer/privacy.php?privacy=brief`
export const studioUrl = `${WORKFLOW_URL}studio/`
export const digitalShowcaseUrl = 'https://shopdigitalcustom.com/'
export const accountInfoUrl = `${WORKFLOW_URL}dealer/dealer_manager.php?dealer_action=details`
export const customModelsUrl = `${WORKFLOW_URL}share/models.php?model_action=custom_models`
export const optionModelVisibilityUrl = `${WORKFLOW_URL}share/models.php?model_action=visibility`
export const shippingHoldUrl = `${WORKFLOW_URL}dealer/dealer_manager.php?dealer_action=hold_orders`
export const changePasswordUrl = `${WORKFLOW_URL}login.php?login_action=update_pw`
export const trendReportUrl = `${WORKFLOW_URL}share/dealer_trend.php`
export const discountsUrl = `${WORKFLOW_URL}dealer/discounts.php`
export const manageCreditCardsUrl = `${WORKFLOW_URL}dealer/payment_manager.php?payment_action=list_cards`
export const paymentHistoryUrl = `${WORKFLOW_URL}dealer/payment_history.php`
export const paymentErrorsUrl = `${WORKFLOW_URL}dealer/payment_manager.php?payment_action=payment_resolution`
export const invoicesUrl = `${WORKFLOW_URL}dealer/invoices.php`
export const switchToTrinityUrl = `${WORKFLOW_URL}trinity/`
export const switchToAgentUrl = `${WORKFLOW_URL}agent/`
export const logoutUrl = `${WORKFLOW_URL}login.php?login_action=logout`
